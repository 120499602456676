<template>
  <div class="wrap-bottom">
    <div class="table-header">
      <div class="theader-left">
        <span>消息列表</span>
      </div>
      <div class="theader-right">
        <div class="wrap-iv">
          <el-input v-model.trim="inputValue"
                    placeholder="请输入标题，内容" />
        </div>
        <el-select filterable
                   v-model="newType"
                   placeholder="请选择消息类型">
          <el-option v-for="item in newsType"
                     :key="item.Id"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <el-select filterable
                   v-model="newState"
                   class=""
                   placeholder="请选择状态">
          <el-option v-for="item in newsState"
                     :key="item.Id"
                     :label="item.label"
                     :value="item.Id">
          </el-option>
        </el-select>
        <el-button @click="handleQuery"
                   size="medium">查询</el-button>
        <el-button @click="handleReset"
                   style="margin-left: 0px"
                   size="medium">重置</el-button>
      </div>
    </div>
    <PublicTable ref="publicTable"
                 :tableHeaders2="tableHeaders2"
                 @changePage="handlePageChange">
      <template #td_Id="scope">
        <el-button size="mini"
                   type="text"
                   @click="openNews(scope.$index, scope.row)">打开</el-button>
        <el-button size="mini"
                   type="text"
                   @click="delNews(scope.$index, scope.row)">删除</el-button>
      </template>
    </PublicTable>
  </div>
  <el-dialog v-model="DialogVisible"
             width="40%"
             custom-class="wrap-dialog">
    <div>
      <hr />
      <p>发件人：{{ newsItem.SenderName }}</p>
      <p>消息类型：{{ newsItem.Type }}</p>
      <p>发件时间：{{ newsItem.SendTime }}</p>
      <hr />
      <span v-html='newsItem.Content[0]'></span>
      <span v-html='newsItem.Content[1]'
            @click="skipI(newsItem.Content[3])"></span>
      <span v-html='newsItem.Content[2]'></span>
    </div>

    <template #title>
      <span class="dialog-title">
        <span><i class="el-icon-message"></i>{{ newsItem.Title }}</span>
      </span>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DialogVisible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import PublicTable from '@/components/table/PublicTable.vue'
export default {
  components: {
    PublicTable,
  },
  data() {
    return {
      newsType: [
        { Id: '1', label: '请选择消息类型', value: '' },
        { Id: '2', label: '系统通知', value: '系统通知' },
        { Id: '3', label: '站内信', value: '站内信' },
        { Id: '4', label: '通知', value: '通知' },
      ],
      newType: '',
      newsState: [
        { Id: '', label: '请选择状态' },
        { Id: '0', label: '未读' },
        { Id: '1', label: '已读' },
      ],
      newState: '',
      selectOption: '',
      queryId: 'Message_list',
      DialogVisible: false,
      inputValue: '', //搜索的内容
      tableHeaders2: [], //表头,
      para: {
        queryId: '',
        PageInfo: { pageNum: 1, pageSize: 10 },
        conditions: null,
      },
      newsItem: {},
    }
  },
  methods: {
    skipI(row) {
      let me = this
      if (row != 1) {
        this.DialogVisible = false
        let data = JSON.parse(row)
        me.$ajax
          .get(
            me.$appConfig.apiUrls.ywxtApi +
              '/api/YW/GetTaskId?InstanceId=' +
              data.Id
          )
          .then(async function (response) {
            if (response.data.IsSuccess) {
              data.taskid = response.data.Data
              me.$router.push({
                path: '/buslist/flowinfo',
                query: { row: JSON.stringify(data) },
              })
            } else {
              data.taskid = ''
              me.$router.push({
                path: '/buslist/flowinfo',
                query: { row: JSON.stringify(data) },
              })
            }
          })
        //me.$router.push({ path: '/buslist/flowinfo', query: { row: row } })
      }
    },
    openNews(index, row) {
      this.newsItem = row
      let r = this.newsItem.Content
      if (r.substring(0, 8) == 'opentask') {
        r = this.newsItem.Content.replace('opentask', '').split('|')
        this.newsItem.Content = r
      } else {
        let arr = [0]
        arr[0] = this.newsItem.Content
        this.newsItem.Content = arr
      }
      // console.log(r);
      let me = this
      this.$ajax
        .post(
          this.$appConfig.apiUrls.messageApi +
            '/api/Message/ReadMessage?id=' +
            row.Id
        )
        .then(async function (response) {
          if (response.data.IsSuccess) {
            await me.$store.dispatch('getUserInfo')
            me.whereconditions()
          } else {
            console.log(response.data.errorMessage)
          }
        })
      this.DialogVisible = true
    },
    //删除信息
    delNews(index, row) {
      ElMessageBox.confirm('是否删除此条消息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await this.$ajax.post(
            this.$appConfig.apiUrls.messageApi +
              '/api/Message/DeleteMessage?id=' +
              row.Id
          )
          if (res.data.IsSuccess) {
            ElMessage({
              type: 'success',
              message: '操作成功!',
            })
            await this.$store.dispatch('getUserInfo')
            this.whereconditions()
          } else {
            ElMessage({
              type: 'warning',
              message: '操作失败!',
            })
          }
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消操作',
          })
        })
    },

    whereconditions() {
      this.queryId = 'Message_list'
      let conditions = [
        {
          Key: 'Title,Content',
          Value: '%' + this.inputValue + '%',
          IsCondition: true,
          Operator: 'like',
          LogicalOperator: 'and',
        },
        {
          Key: 'Type',
          Value: this.newType,
          IsCondition: true,
          Operator: '=',
          LogicalOperator: 'and',
        },
        {
          Key: 'Status',
          Value: this.newState,
          IsCondition: true,
          Operator: '=',
          LogicalOperator: 'and',
        },
      ]
      this.getLoadData(this.queryId, conditions)
    },
    //获取表头数据
    getLoadData(queryId, conditions) {
      this.para.queryId = this.queryId
      if (conditions === []) conditions = null
      this.para.conditions = conditions
      let me = this
      this.$ajax
        .get(
          this.$appConfig.apiUrls.tableApi +
            '/api/CommonTable/GetFileds?queryKey=' +
            this.queryId
        )
        .then(function (response) {
          if (response.data.isSuccess) {
            me.tableHeaders2 = response.data.data
            me.getDataList(me.para)
          } else {
            console.log(response.data.errorMessage)
          }
        })
    },
    //获取表格数据
    async getDataList(para) {
      this.para = para
      let me = this
      var res = await this.$ajax.post(
        this.$appConfig.apiUrls.tableApi + '/api/CommonTable/LoadData',
        JSON.stringify(para)
      )
      res = JSON.parse(res.data.data)
      console.log('获取表格数据：', res)
      console.log(me.$store.state.newsCount)
      me.$refs.publicTable.getTData(me.para, res)
    },
    // 分页
    handlePageChange(val) {
      this.para.PageInfo.pageNum = val
      this.getDataList(this.para)
    },
    //重置
    handleReset() {
      this.inputValue = ''
      this.newType = ''
      this.newState = ''
      this.whereconditions()
    },
    //搜索
    handleQuery() {
      this.whereconditions()
    },
  },
  activated() {
    if (this.$store.state.newsCount > 0) {
      this.newState = '0'
    }
    this.whereconditions()
  },
}
</script>

<style scoped>
.table-header {
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrap-iv {
  display: inline-block;
  margin-right: 5px;
}

.clickable {
  color: #3c8dbc;
}

.clickable:hover {
  text-decoration: underline;
}
.theader-right .el-select {
  width: 160px;
}
.theader-right .el-select:last-of-type {
  width: 120px;
  margin: 0 5px;
}
.wrap-dialog .el-dialog__body p {
  line-height: 22px;
}
.wrap-dialog .el-dialog__body hr {
  height: 1px;
  border: none;
  background: #ebeaea;
  margin: 3px;
}
.wrap-dialog .el-dialog__body hr:last-of-type {
  background: #ddd;
}
.dialog-title {
  font-size: 20px;
}
.dialog-title i {
  font-size: 20px;
  margin-right: 5px;
  margin-left: 10px;
}

.dialog-footer .el-button {
  width: 35%;
}
</style>
<style>
.wrap-dialog .el-dialog__body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.wrap-dialog .el-dialog__footer {
  text-align: center;
}
</style>